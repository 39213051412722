
import React from 'react';

import SmartNotesComponent from './SmartNotes';

import flowmz from "../../../utils/images/flowmz.png";
import loading from "../../../utils/icons/loading.png";


export default function SmartNotes(props) {
  return (
    <div className="flex justify-center">
      {props.loading ? <Loader /> :
        props.meetingData ? <div className="box-border h-full w-11/12 mt-1 p-1" >
          <SmartNotesComponent {...props} />
        </div> : <NoMeeting {...props} />}
    </div>
  );
}


const NoMeeting = (props) => {
  return (
    <div className="w-full h-full flex flex-row-reverse" >
      <div className="sm: w-screen h-screen md:w-screen lg:w-screen border-4 border-light-gary-300 
          border-opacity-25 rounded-md shadow-sm p-1 relative bg-gray-50" >
        <div className="flex flex-row" >
          <h1 className="p-5 mt-14 ml-10 text-sm text-gray-600 font-semibold" > No meetings in progress</h1>
          <button onClick={props.getMeetingDetails} className="p-1 mt-16 ml-8 h-10 w-24 font-semibold bg-blue-500 rounded-md" > Refresh </button>
        </div>
      </div>
    </div>
  )
}

const Loader = () => {
  return (
    <div className="w-full h-full flex flex-row-reverse" >
      <div className="h-auto top-1/4 right-1/3 mr-20 flex flex-col w-80 object-center p-10" >
        <img src={loading} alt="Loader" class="animate-spin h-20 w-20 p-3 ml-16 mt-5" viewBox="0 0 24 24" />
      </div>
    </div>
  )
}