import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import meetingReducer from './Reducers/meetingReducer';
import workflowReducer from './Reducers/workflowReducer';
import userReducer from './Reducers/userReducer';
import triggersReducer from './Reducers/triggersReducer';


const rootReducer = combineReducers({
    meetingState: meetingReducer,
    workflowState: workflowReducer,
    userState: userReducer,
    triggerState: triggersReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));


export default store;