
import axios from "axios";
import { WebClient } from "@slack/web-api";

import * as actionTypes from "../types";
import userWorkflows from "../../mockData/availableWorkflows.json";


import { urls } from "../../utils/constants";


// export const getWorkflowsAction = (data) => async dispatch => {
//     dispatch(workflowLoader(true));
//     try {
//         let res = await axios.get(urls.workflows, {
//             headers: {
//                 token: localStorage.getItem("token")
//             }
//         });
//         if (res && res.data && res.data.workflows && res.data.workflows.length > 0) {
//             dispatch(getWorkflowsSuccess(res.data.workflows));
//             dispatch(workflowLoader(false));
//         } else {
//             dispatch(getWorkflowsSuccess([]));
//             dispatch(workflowLoader(false));
//         }
//         // setTimeout(() => {
//         //     dispatch(getWorkflowsSuccess(userWorkflows.worklows));
//         //     dispatch(workflowLoader(false));
//         // }, 5000);
//     } catch (e) {
//         dispatch(getWorkflowsSuccess([]));
//         dispatch(workflowLoader(false));
//     }
// }


export const getUserSlackChannels = (token) => async dispatch => {
    dispatch(getUserSlackChannelsLoading(true));
    try {
        const client = new WebClient(token);

        let channelRes = await client.conversations.list({ types: "public_channel,private_channel,im" });
        // console.log("channelRes", channelRes)
        let usersRes = await client.users.list();

        if (channelRes && channelRes.channels && usersRes && usersRes.members) {
            let data = {
                channels: channelRes.channels,
                users: usersRes.members
            }
            dispatch(getUserSlackChannelsSuccess(data));
        } else {
            if (channelRes && channelRes.channels) {
                let data = {
                    channels: channelRes.channels,
                    users: []
                }
                dispatch(getUserSlackChannelsSuccess(data));
            } else if (usersRes && usersRes.members) {
                let data = {
                    channels: [],
                    users: usersRes.members
                }
                dispatch(getUserSlackChannelsSuccess(data));
            } else {
                dispatch(getUserSlackChannelsSuccess({ channels: [], users: [] }));
            }
        }
    } catch (e) {
        dispatch(getUserSlackChannelsSuccess({ channels: [], users: [] }));
    }
}

const getMsgFormat = async (trigger, notes) => {
    let msg = `*${trigger}:* \n `
    await notes.map(note => {
        msg += "   " + ` • _${note}_ \n `
    });
    return msg
}

export const sendSlackMessage = (token, data, cb) => async dispatch => {
    try {
        const client = new WebClient(token);
        let msg = await getMsgFormat(data.triggerWord, data.notes)

        console.log("msgggg", msg)

        let res = await client.chat.postMessage({
            channel: data.channel,
            text: msg
        });
        cb(true);
    } catch (e) {
        cb(false);
    }
}


const getUserSlackChannelsSuccess = (channels) => {
    return {
        type: actionTypes.UPDATE_SLACK_CHANNELS,
        data: channels
    }
}


const getUserSlackChannelsLoading = (isLoading) => {
    return {
        type: actionTypes.GET_SLACK_CHANNELS_LOADING,
        data: isLoading
    }
}


const sendingSlackMessage = (sendingMessageList) => {
    return {
        type: actionTypes.SEND_SLACK_MESSAGE,
        data: sendingMessageList
    }
}

// const getWorkflowsSuccess = (userDetails) => {
//     return {
//         type: actionTypes.GET_WORKFLOW_DETAILS,
//         data: userDetails
//     }
// }


// const workflowLoader = (isLoading) => {
//     return {
//         type: actionTypes.GET_WORKFLOW_DETAILS_LOADER,
//         data: isLoading
//     }
// }


export const sendMailAction = (token, data, cb) => async dispatch => {
    try {
        let response = await axios.post(urls.emailTrigger, data, {
            headers: {
                token: token
            }
        });
        cb(true);
    } catch (e) {
        cb(false);
    }
}


export const scheduleCalendarEvents = (token, data, cb) => async dispatch => {
    try {
        let zoomData = {
            topic: data.title,
            type: 2,
            start_time: data.start,
            duration: 40,
            agenda: data.title,
            timezone: data.timeZone
        }
        let zoomEvent = await axios.post(urls.scheduleZoomEvent, zoomData, {
            headers: {
                token: token
            }
        });
        if (zoomEvent && zoomEvent.data && zoomEvent.data.success) {
            let meetingUrl = zoomEvent.data.eventDetails.meetingUrl;
            let description = `${data.title}\n \n Join zoom meeting \n${meetingUrl}\n
                Meeting ID: ${zoomEvent.data.eventDetails.meetingId}\nPasscode: ${zoomEvent.data.eventDetails.meetingPin}\n`;
            let calendarData = {
                "summary": data.title,
                "location": meetingUrl,
                "description": description,
                "start": {
                    "dateTime": data.start,
                    "timeZone": data.timeZone
                },
                "end": {
                    "dateTime": data.end,
                    "timeZone": data.timeZone
                },
                "attendees": data.attendees
            }
            let calendarEvent = await axios.post(urls.scheduleCalendarEvent, calendarData, {
                headers: {
                    token: token
                }
            });
            if(calendarEvent && calendarEvent.data) {
                cb(true);
            } else {
                cb(false); 
            }
        } else {
            cb(false);
        }
    } catch (e) {
        cb(false);
    }
}