import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';
import MuiAlert from '@mui/material/Alert';
import io from "socket.io-client";

import ShareWorkflow from './ShareWorkflow';
import ScheduleMeeting from './SchedulerMeeting';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SmartNotes(props) {

  const [meetingEndedNotify, setNotify] = useState(false);

  const socket = io('wss://proxylabs.flow.zoimeet.com');
  
  socket.on('connect', () => {
    console.log("socket connected", socket.id);
    let data = {
      type: "ZAPP",
      meetingID: `${props.meetingData.id}`
    }
    socket.emit("joinMeeting", data)
  });

  socket.on('disconnect', (event) => {
    console.log("socket disconnected", event)
  });

  socket.on('error', (err) => {
    console.log("Error on socket", err);
  });

  socket.on('meetingEnded', (data) => {
    console.log("Meeting ended .............")
    socket.disconnect();
    setNotify(true);
    setTimeout(() => {
      window.location.reload();
    }, 10000);
  });

  socket.on('trigger', (triggerData) => {
    // console.log("trigger", triggerData);
    let workflows = triggerData.filter(i => i.target === "WORKFLOW");
    let schedules = triggerData.filter(i => i.target === "SCHEDULE");
    if (workflows.length > 0) {
      props.showNextStepsAction(true);
    }
    if (schedules.length > 0) {
      props.showSchedulerAction(true);
    }
  });

  // let interval = null;

  // useEffect(() => {
  // props.getUserTriggers(props.meetingData, () => { });
  // interval = setInterval(() => {
  //   props.getUserTriggers(props.meetingData, (isMeetingLive) => {
  //     if (!isMeetingLive) {
  //       setNotify(true);
  //       clearInterval(interval);
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 10000);
  //     }
  //   });
  // }, 5000);
  // }, []);

  // const getTargetModule = (item) => {
  //   switch (item.target) {
  //     case "WORKFLOW":
  //       return <ShareWorkflow triggerItem={item} {...props} />
  //     case "SCHEDULE":
  //       return <ScheduleMeeting triggerItem={item} {...props} />
  //     default:
  //       return <ShareWorkflow triggerItem={item} {...props} />
  //   }
  // }

  return (
    <div className="flex flex-col justify-center">
      {/* {props.triggers.map((item) => getTargetModule(item))} */}
      {props.showNextSteps && <ShareWorkflow {...props} />}
      {props.showScheduler && <ScheduleMeeting {...props} />}
      <Snackbar
        open={meetingEndedNotify}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        TransitionComponent={Fade}
        key={Fade.name}
      ><Alert severity="error" sx={{ width: '100%' }}>
          Meeting has been ended, this page will refresh in 10 seconds
        </Alert>
      </Snackbar>
    </div>
  );
}
