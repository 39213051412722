import { connect } from "react-redux";

import Layout from "../component/Layout";

import { loginUserAction } from "../../../redux/Actions/userAction";
import { getMeetingDetails } from "../../../redux/Actions/meetingAction";

const mapStateToProps = state => {
    return {
        user: state.userState.user,
        token: state.userState.token,
        loading: state.userState.loading,
        loggedIn: state.userState.loggedIn,
        meetingData: state.meetingState.meetingData,
        meetingDataLoading: state.meetingState.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loginUserAction: (payload) => dispatch(loginUserAction(payload)),
        getMeetingDetails: (payload) => dispatch(getMeetingDetails(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)