import { connect } from "react-redux";

import Transcripts from "../component/Transcripts";

const mapStateToProps = state => {
    return {
        state: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Transcripts)