
import * as actionTypes from "../types";
import axios from "axios";
// import https from 'https';
// import request from "request";
// import fs from 'fs';
// import fetch from "node-fetch";

import { urls } from "../../utils/constants";

import triggerData from "../../mockData/triggers.json";

let retryCount = 0;

// // https.globalAgent.options.rejectUnauthorized = false;

// const getCertificateData = async () => {
//         let headers = new Headers();
//         headers.append("Content-Type", "application/json");
//         let init = {
//             method: "GET",
//             headers: headers,
//         };
//         await fetch("/cert/cert.pem", init)
//             .then((response) => {
//                 console.log("response",response);
//                 return response;
//             })
//             // .then((obj) => {
//             //     console.log(obj);
//             //     return obj;
//             // });
//     }

export const getUserTriggers = (meetingData, cb) => async dispatch => {
    dispatch(triggerLoader(true));
    try {
        // dispatch(getUserTriggersSuccess(triggerData));
        // cb(true);

        let res = await axios.post(urls.triggers, {
            ip: meetingData.vm_address
        }, {
            headers: {
                token: localStorage.getItem("token")
            }
        });

    // _user - {"username":"nithan@zoimeet.com","fullname":"Nithan Pandi Mayalagu","email_id":"nithan@zoimeet.com","organisation":null,"registered_time":"2021-08-02T07:27:36.000Z","slack_token":"xoxb-498027614866-2513161832135-mp9M8JT7tDlzas1UtRdIgjoF","features":[{"featureName":"SPEAKER"},{"featureName":"SUMMARY"},{"featureName":"TRANSCRIPT"}],"hubspot":true,"slack":true}

    // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoyNn0sImlhdCI6MTYzMjgwNDE5MCwiZXhwIjoxNjMzNDA4OTkwfQ.ODAFPL651iEtoh3nKhVWRPEAgeqo4kjKYIqy4xprM6g
        

        //   req.on("error", (e) => console.log("eeeeeeeeeee", e))
        if (res && res.data && res.data.success && res.data.triggers.length > 0) {
            let workflows = res.data.triggers.filter(i => i.target === "WORKFLOW");
            let schedules = res.data.triggers.filter(i => i.target === "SCHEDULE");
            if(workflows.length > 0) {
                dispatch(showNextStepsAction(true));
            }
            if(schedules.length > 0) {
                dispatch(showSchedulerAction(true));
            }
            // console.log("res.data", res.data)
            // dispatch(getUserTriggersSuccess(res.data.triggers));
            // dispatch(triggerLoader(false));
        } else {
            // dispatch(getUserTriggersSuccess([]));
            // dispatch(triggerLoader(false));
        }
        retryCount = 0;
        cb(res.data.live);
    } catch (e) {
        // console.log("error at catch", e)
        // dispatch(getUserTriggersSuccess([]));
        // dispatch(triggerLoader(false));
        // retryServer(meetingData, cb);
        if(retryCount < 5) {
            retryCount += 1;
        } else {
            cb(false);
        }
    }
}


const retryServer = async (meetingData, cb) => {
    if(retryCount < 3) {
        try {
            // let agent = new https.Agent({
            //     rejectUnauthorized: false
            // })
            let res = await axios.get(`https://${meetingData.vm_address}:8080/triggers`);
            if (res && res.data && res.data && res.data.live) {
                cb(res.data.live);
            } else {
                cb(false);
            }
        } catch (e) {
            retryCount += 1;
            setTimeout(() => {
                retryServer(meetingData, cb); 
            }, 3000);
        }
    } else {
        cb(false);
    }
}


const getUserTriggersSuccess = (triggers) => {
    return {
        type: actionTypes.GET_TRIGGERS,
        data: triggers
    }
}


export const showNextStepsAction = (show) => {
    return {
        type: actionTypes.SHOW_NEXTSTEPS,
        data: show
    }
}


export const showSchedulerAction = (show) => {
    return {
        type: actionTypes.SHOW_SCHEDULER,
        data: show
    }
}


const triggerLoader = (isLoading) => {
    return {
        type: actionTypes.GET_TRIGGERS_LOADER,
        data: isLoading
    }
}


export const updateTriggersAction = (data) => async dispatch => {
    dispatch(updateNewTriggers(data))
}


export const updateUserNotes = (data) => async dispatch => {
    dispatch({
        type: actionTypes.UPDATE_USER_NOTES,
        data: data
    })
}



const updateNewTriggers = (data) => {
    return {
        type: actionTypes.UPDATE_TRIGGERS,
        data: data
    }
}
