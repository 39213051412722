import { connect } from "react-redux";

import MyNotes from "../component/MyNotes";

const mapStateToProps = state => {
    return {
        state: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyNotes)