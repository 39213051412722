
export const GET_MEETING_DETAILS = "GET_MEETING_DETAILS";
export const GET_MEETING_DETAILS_LOADER = "GET_MEETING_DETAILS_LOADER";

export const GET_WORKFLOW_DETAILS = "GET_WORKFLOW_DETAILS";
export const GET_WORKFLOW_DETAILS_LOADER = "GET_WORKFLOW_DETAILS_LOADER";

export const GET_USER = "GET_USER";
export const GET_USER_LOADER = "GET_USER_LOADER";

export const GET_TRIGGERS = "GET_TRIGGERS";
export const UPDATE_TRIGGERS = "UPDATE_TRIGGERS";
export const GET_TRIGGERS_LOADER = "GET_TRIGGERS_LOADER";
export const SHOW_NEXTSTEPS = "SHOW_NEXTSTEPS";
export const SHOW_SCHEDULER = "SHOW_SCHEDULER";
export const UPDATE_USER_NOTES = "UPDATE_USER_NOTES";   

export const GET_SLACK_CHANNELS = "GET_SLACK_CHANNELS";
export const UPDATE_SLACK_CHANNELS = "UPDATE_SLACK_CHANNELS";
export const GET_SLACK_CHANNELS_LOADING = "GET_SLACK_CHANNELS_LOADING";
export const SEND_SLACK_MESSAGE = "SEND_SLACK_MESSAGE";