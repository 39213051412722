import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import MuiAlert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';

import smartnotes_logo from "../../../utils/icons/smartnotes_logo.png";
import notesicon from "../../../utils/icons/notesicon.png";
import slack from "../../../utils/images/slacklogo.png";
import email from "../../../utils/images/email.png";
import hubspot from "../../../utils/images/hubspot.svg";
import send_icon from "../../../utils/icons/send2.png";
import downside from "../../../utils/icons/downside.png";
import loading from "../../../utils/icons/loading.png";

import meetingData from "../../../mockData/meetingDetails.json";
import workFlowData from "../../../mockData/availableWorkflows.json";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function ShareWorkflow(props) {

    // console.log(props)

    const [userDefinedTriggers, setUserDefinedTriggers] = useState([]);
    const [activeWorkflowTargets, setActiveWorkflowTargets] = useState([]);
    const [newTrigger, setNewTrigger] = useState("");
    const [expand, setExpand] = useState(true);
    const [dismiss, setDismiss] = useState(false);


    const handleChange = (e) => {
        setNewTrigger(e.target.value);
    }

    const handleEnterPress = (e) => {
        if (e.charCode == 13 && e.key == "Enter" && newTrigger.length > 0) {
            let triggers = [...props.userNotes, newTrigger];
            // setUserDefinedTriggers(triggers);
            props.updateUserNotes(triggers);
            setNewTrigger("");
        }
    }

    const updateActiveWorkflowTargets = (workflow) => {
        if (activeWorkflowTargets.includes(workflow)) {
            let newList = activeWorkflowTargets.filter(i => i != workflow);
            setActiveWorkflowTargets(newList);
        } else {
            // let newList = [...activeWorkflowTargets, workflow];
            setActiveWorkflowTargets([workflow]);
        }
    }

    const getWorkflowIcon = (workflow) => {
        switch (workflow) {
            case "SLACK":
                return slack;
            case "EMAIL":
                return email;
            case "HUBSPOT":
                return hubspot;
            default:
                return null
        }
    }

    const getActiveTriggerPointsList = (workflow, index) => {
        switch (workflow) {
            case "SLACK":
                return <SlackComponent notes={userDefinedTriggers}
                    activeWorkflowTargets={activeWorkflowTargets}
                    setActiveWorkflowTargets={setActiveWorkflowTargets} 
                    updateActiveWorkflowTargets={updateActiveWorkflowTargets} {...props} />
            case "EMAIL":
                return <EmailComponent notes={userDefinedTriggers}
                    activeWorkflowTargets={activeWorkflowTargets}
                    setActiveWorkflowTargets={setActiveWorkflowTargets}
                    updateActiveWorkflowTargets={updateActiveWorkflowTargets} {...props} />
            case "HUBSPOT":
                return null
            // return <HubspotComponent notes={userDefinedTriggers}
            //     activeWorkflowTargets={activeWorkflowTargets}
            //     setActiveWorkflowTargets={setActiveWorkflowTargets} />
            default:
                return null
        }
    }

    const removeTrigger = () => {
        // let newTriggers = props.triggers.filter(i => i.id !== triggerId);
        // props.updateTriggersAction(newTriggers);
        props.showNextStepsAction(false)
    }

    const removeUserDefinedTrigger = (trigger) => {
        let newArr = props.userNotes.filter((item) => item !== trigger);
        // setUserDefinedTriggers(newArr);
        props.updateUserNotes(newArr);
    }

    return (
        <div className="h-full w-full border-2 shadow-sm rounded-lg mt-2" >
            <div style={{ background: "rgb(191,218,255)" }} className="flex flex-row rounded-t-lg p-2 justify-between" 
            // onClick={() => setExpand(!expand)}
             >
                <div className="flex flex-row" >
                    <img src={smartnotes_logo} alt="icon" className="w-5 h-5 ml-2" />
                    <p className="ml-2 font-bold text-xs" >Add your follow up steps</p>
                </div>
                {/* <div className="cursor-pointer"  >
                    <img src={downside} alt="icon" className={`w-5 h-5 p-1 mr-2 hover:shadow-sm rounded-md ${expand && 'transform rotate-180'}`} />
                </div> */}
            </div>
            {expand && <>
                <div className="flex flex-col pl-3 pt-2 pr-3" >
                    <div className="flex flex-col p-3" >
                        {props.userNotes && props.userNotes.map((triggerWord, index) => {
                            return (
                                <div key={index} className="flex flex-row" >
                                    <img onClick={() => removeUserDefinedTrigger(triggerWord)} src={notesicon} alt="icon" className="w-5 h-5 p-1 rounded-xl hover:shadow-lg border border-white hover:border-gray-200 cursor-pointer" />
                                    <p className="text-xs font-semibold overflow-ellipsis overflow-hidden ..." >{triggerWord}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="flex flex-col divide-y p-2" >
                    <input value={newTrigger} type="text" onChange={handleChange} onKeyPress={handleEnterPress}
                        placeholder="Add a next step" className="bg-gray-100 ml-5 rounded-sm border-0 p-2 w-11/12 border-gray-300 text-sm border-b-2" />
                    <div className="border-gray-400 border-b-4 m-5 mb-0" ></div>
                </div>
                <div className="p-1 justify-start grid grid-cols-3 lg:grid-cols-6 gap-1 ml-5 mb-5" >
                    {workFlowData.worklows.map((item, index) => {
                        return (
                            (item.workflow !== "HUBSPOT") && <div onClick={() => updateActiveWorkflowTargets(item.workflow)} key={index} >
                                <img src={getWorkflowIcon(item.workflow)} style={{ background: item.workflow == "SLACK" ? "rgb(74,21,75)" : "rgb(18,173,126)" }} className={`p-3 cursor-pointer hover:shadow-2xl object-contain rounded-md object-center h-12 w-12`} />
                            </div>
                        )
                    })}
                    <button onClick={() => removeTrigger()} type="button" className="rounded-md h-12 w-24 hover:shadow-lg border text-gray-600 hover:text-gray-800 border-black border-opacity-50" > Dismiss </button>
                </div>
                <div>
                    {activeWorkflowTargets.map((workflow, index) => {
                        return getActiveTriggerPointsList(workflow, index)
                    })}
                </div>
            </>}
        </div>
    );
}

// const HubspotComponent = ({ notes, activeWorkflowTargets, setActiveWorkflowTargets }) => {

//     const [contactsArr, setContactsArr] = useState([]);
//     const [attendeesContacts, setAttendeesContacts] = useState("");

//     const sendToMyContact = (e) => {
//         if (contactsArr.includes(meetingData.admin)) {
//             let newList = contactsArr.filter(i => i != meetingData.admin);
//             setContactsArr(newList);
//         } else {
//             let newList = [...contactsArr, meetingData.admin];
//             setContactsArr(newList);
//         }
//     }

//     const handleChange = (e) => {
//         setAttendeesContacts(e.target.value);
//     }

//     const pushToHubspot = () => {
//         if (attendeesContacts.length > 0) {
//             let contactList = attendeesContacts.split(",");
//             let newList = [...contactsArr, ...contactList];
//             setContactsArr(newList);
//         }
//         if (notes.length > 0 && contactsArr.length > 0) {
//             // push to hubspot
//             let data = {
//                 type: "HUBSPOT",
//                 contacts: contactsArr,
//                 meetingId: meetingData.meetingId,
//                 meetingTitle: meetingData.title,
//                 userId: 1,
//                 notes: notes
//             }
//             console.log("Push to hubspot", data);
//             setContactsArr([]);
//             setAttendeesContacts("");
//             let newTargets = activeWorkflowTargets.filter(i => i != "HUBSPOT");
//             setActiveWorkflowTargets(newTargets);
//         }
//     }

//     return (
//         <div className="flex flex-col bg-gray-100 ml-10 rounded-md border-0 p-5 w-10/12 border-gray-500 text-sm mb-5" >
//             <div className="flex flex-row p-2" >
//                 <input onChange={sendToMyContact} type="checkbox" className="text-sm mt-1 p-1 h-4 w-6" />
//                 <span className="text-md ml-3 opacity-80 font-medium" >Send to my Hubspot contact</span>
//             </div>
//             <div className="flex flex-row p-2" >
//                 <input onChange={handleChange} value={attendeesContacts} type="text" placeholder="Add more contact emails separated by a comma" className="bg-white w-80 rounded-md p-3 border border-opacity-20 text-sm" />
//             </div>
//             <button type='button' onClick={pushToHubspot} >
//                 <img src={send_icon} className={`p-2 ml-3 mt-2 hover:shadow-2xl hover:bg-gray-700 object-contain rounded-md bg-gray-600 object-center h-8 w-10`} />
//             </button>
//         </div>
//     )
// }


const EmailComponent = (props) => {

    // console.log(props)

    const [emailsArr, setEmailsArr] = useState([]);
    const [attendeesEmails, setAttendeesEmails] = useState("");
    const [loader, setLoader] = useState(false);
    const [notify, setNotify] = useState({
        show: false,
        type: "",
        msg: ""
    });

    const sendToMyEmail = (e) => {
        if (emailsArr.includes(props.userData.email_id)) {
            let newList = emailsArr.filter(i => i != props.userData.email_id);
            setEmailsArr(newList);
        } else {
            let newList = [...emailsArr, props.userData.email_id];
            setEmailsArr(newList);
        }
    }

    const handleChange = (e) => {
        setAttendeesEmails(e.target.value);
    }

    const pushToEmail = () => {
        setLoader(true);
        // // if (attendeesEmails.length > 0) {
        let contactList = attendeesEmails.length > 0 ? attendeesEmails.split(",") : [];
        console.log(contactList)
        let newList = [...emailsArr, ...contactList];
        // // setEmailsArr(newList);
        // // }
        let token = localStorage.getItem("token");
        if (newList.length > 0) {
            let data = {
                type: "EMAIL",
                emails: newList,
                meetingId: props.meetingData.id,
                meetingTitle: props.meetingData.meeting_name,
                userId: 1,
                notes: props.userNotes,
                // triggerWord: props.triggerItem.trigger_phrase
                triggerWord: "Follow up steps"
            }
            // console.log("Push to Email", data);
            props.sendMailAction(token, data, (success) => {
                if (success) {
                    setNotify({
                        show: true,
                        type: "success",
                        msg: "Email sent"
                    });
                    setEmailsArr([]);
                    setAttendeesEmails("");
                    setLoader(false);
                    setTimeout(() => {
                        setNotify({
                            show: false,
                            type: "",
                            msg: ""
                        });
                    props.updateActiveWorkflowTargets("EMAIL");
                    }, 1000);
                } else {
                    setNotify({
                        show: true,
                        type: "error",
                        msg: "Something went wrong while sending email"
                    });
                    setLoader(false);
                    setTimeout(() => {
                        setNotify({
                            show: false,
                            type: "",
                            msg: ""
                        });
                    }, 2000);
                }
            })
        }
    }

    return (
        <div className="flex flex-col bg-gray-100 ml-10 rounded-md border-0 p-5 w-10/12 border-gray-500 text-sm mb-5" >
            <div className="flex flex-row p-2" >
                <input onChange={sendToMyEmail} type="checkbox" className="text-sm mt-1 p-1 h-4 w-6" />
                <span className="text-md ml-3 opacity-80 font-medium" >Send to my Email</span>
            </div>
            <div className="flex flex-row p-2" >
                <input onChange={handleChange} value={attendeesEmails} type="text" placeholder="Add more emails separated by a comma" className="bg-white w-80 rounded-md p-3 border border-opacity-20 text-sm" />
            </div>
            <Snackbar
                open={notify.show}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={3000}
                TransitionComponent={Fade}
                key={Fade.name}
            ><Alert severity={notify.type} sx={{ width: '100%' }}>
                    {notify.msg}
                </Alert>
            </Snackbar>
            {loader ?
                <CircularProgress className="w-5 h-5 p-2 ml-2" />
                : <>{props.userNotes.length > 0 && (emailsArr.length > 0 || (attendeesEmails.length > 0 && attendeesEmails.includes("@") && attendeesEmails.includes("."))) ? <button type="button" onClick={pushToEmail} >
                    <img src={send_icon} style={{ background: "rgb(18,173,126)" }} className={`p-2 ml-3 mt-2 hover:shadow-2xl hover:bg-green-700 object-contain rounded-md object-center h-8 w-10`} />
                </button> :
                    <button type="button" disabled>
                        <Tooltip title={!props.userNotes.length > 0 ? `Add notes` : `Add emails to send`}>
                            <img src={send_icon} className={`p-2 ml-3 mt-2 hove cursor-not-allowed object-contain rounded-md bg-gray-400 object-center h-8 w-10`} />
                        </Tooltip></button>}
                </>}
        </div>
    )
}


const SlackComponent = (props) => {


    // console.log("-----------", props)

    let slackToken = localStorage.getItem("slackToken");

    const [selectedChannel, setSelectedChannel] = useState("");
    // const [selectedUser, setSelectedUser] = useState("");
    const [to, setTo] = useState("channel");
    const [loader, setLoading] = useState(false);
    const [notify, setNotify] = useState({
        show: false,
        type: "",
        msg: ""
    });

    useEffect(() => {
        if (!props.slackChannels) {
            props.getUserSlackChannels(slackToken);
        }
        if (props.slackChannels && props.slackChannels.length > 0) {
            setSelectedChannel(props.slackChannels[0].id)
        }
    }, [props.slackChannels])

    const onSubmit = () => {
        setLoading(true);
        let data = {
            notes: props.userNotes,
            channel: selectedChannel,
            // triggerWord: props.triggerItem.trigger_phrase
            triggerWord: "Follow up steps"
        }
        props.sendSlackMessage(slackToken,
            data, (success) => {
                if (success) {
                    setNotify({
                        show: true,
                        type: "success",
                        msg: "Message sent"
                    });
                    setLoading(false);
                    setTimeout(() => {
                        setNotify({
                            show: false,
                            type: "",
                            msg: ""
                        });
                    props.updateActiveWorkflowTargets("SLACK");
                    }, 1000);
                } else {
                    setNotify({
                        show: true,
                        type: "error",
                        msg: "Something went wrong while sending slack message"
                    });
                    setLoading(false);
                    setTimeout(() => {
                        setNotify({
                            show: false,
                            type: "",
                            msg: ""
                        });
                    }, 2000);
                }
            });
        // console.log("************", data);
    }

    return (
        <div className="flex flex-col bg-gray-100 ml-10 rounded-md border-0 p-5 w-10/12 border-gray-500 text-sm mb-5" >
            {slackToken && (slackToken !== "null" || slackToken !== null) ? <div>
                <div className="col-span-6 sm:col-span-3 p-2">
                    <label htmlFor="to" className="block text-xs font-bold text-gray-800">
                        Send as
                    </label>
                    <select
                        id="to"
                        name="to"
                        autoComplete="send to"
                        value={to}
                        onChange={(e) => {
                            if (e.target.value === "direct") {
                                setSelectedChannel(props.slackDirects.length > 0 ? props.slackDirects[0].id : "");
                                setTo(e.target.value);
                            } else {
                                setSelectedChannel(props.slackChannels.length > 0 ? props.slackChannels[0].id : "");
                                setTo(e.target.value);
                            }
                        }}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                        <option value="channel" >Channel message</option>
                        <option value="direct" >Direct message</option>
                    </select>
                </div>
                {to === "channel" ? <div className="col-span-6 sm:col-span-3 p-2">
                    <label htmlFor="channel" className="block text-xs font-bold text-gray-800">
                        Slack channel
                    </label>
                    {props.slackChannelsLoading ?
                        <div class="p-1 max-w-sm w-full mx-auto">
                            <div class="animate-pulse flex space-x-4">
                                <div class="flex-1 space-y-4 py-1">
                                    <div class="space-y-2">
                                        <div class="">
                                            <select
                                                disabled
                                                className="h-8 bg-gray-300 rounded mt-1 block w-full py-2 px-3 border border-gray-300 shadow-sm focus:outline-none"
                                            >
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : props.slackChannels && props.slackChannels.length > 0 ? <select
                            id="channel"
                            name="channel"
                            autoComplete="channel"
                            value={selectedChannel}
                            onChange={(e) => setSelectedChannel(e.target.value)}
                            className="mt-1 cursor-pointer block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                            {/* <option selected >Select channel</option> */}
                            {props.slackChannels.map((item, index) => {
                                return item.name ? <option key={index} value={item.id} >{item.name}</option> : null
                            })}
                        </select>
                            : <p
                                disabled
                                className="mt-1 cursor-not-allowed block w-full py-2 px-3 text-sm align-middle border border-gray-300 bg-gray-300 rounded-md shadow-sm "
                            >
                                No channels found
                            </p>}
                </div> :
                    <div className="col-span-6 sm:col-span-3 p-2">
                        <label htmlFor="channel" className="block text-xs font-bold text-gray-800">
                            Slack member
                        </label>
                        {props.slackChannelsLoading ?
                            <div class="p-1 max-w-sm w-full mx-auto">
                                <div class="animate-pulse flex space-x-4">
                                    <div class="flex-1 space-y-4 py-1">
                                        <div class="space-y-2">
                                            <div class="">
                                                <select
                                                    disabled
                                                    className="h-8 bg-gray-300 rounded mt-1 block w-full py-2 px-3 border border-gray-300 shadow-sm focus:outline-none"
                                                >
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : props.slackDirects && props.slackDirects.length > 0 ? <select
                                id="user"
                                name="user"
                                autoComplete="user"
                                value={selectedChannel}
                                onChange={(e) => setSelectedChannel(e.target.value)}
                                className="mt-1 cursor-pointer block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                {/* <option selected >Select channel</option> */}
                                {props.slackDirects.map((item, index) => {
                                    return <option key={index} value={item.id} >{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</option>
                                })}
                            </select>
                                : <p
                                    disabled
                                    className="mt-1 cursor-not-allowed block w-full py-2 px-3 text-sm align-middle border border-gray-300 bg-gray-300 rounded-md shadow-sm "
                                >
                                    No members found
                                </p>}
                    </div>}
                <Snackbar
                    open={notify.show}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    autoHideDuration={3000}
                    TransitionComponent={Fade}
                    key={Fade.name}
                ><Alert severity={notify.type} sx={{ width: '100%' }}>
                        {notify.msg}
                    </Alert>
                </Snackbar>
                {loader ?
                    <button disabled className="cursor-not-allowed" type="button" >
                        <img src={loading} className={`p-2 ml-3 mt-2 animate-spin hover:shadow-2xl hover:bg-gary-700 object-contain rounded-md bg-gary-800 object-center h-8 w-10`} />
                    </button> :
                    <>
                        {props.userNotes.length > 0 && selectedChannel.length > 0 ? <button className="cursor-pointer" type="button" onClick={onSubmit}  >
                            <img src={send_icon} style={{ background: "rgb(74,21,75)" }} className={`p-2 ml-3 mt-2 hover:shadow-2xl hover:bg-red-700 object-contain rounded-md object-center h-8 w-10`} />
                        </button> :
                            <Tooltip title={!props.userNotes.length > 0 ? `Add notes` : ''}>
                                <button disabled className="cursor-not-allowed" type="button"  >
                                    <img src={send_icon} className={`p-2 ml-3 mt-2 hove cursor-not-allowed object-contain rounded-md bg-gray-400 object-center h-8 w-10`} />
                                </button>
                            </Tooltip>}
                    </>}
            </div> : <div className="col-span-6 sm:col-span-3 p-2">
                <p className="block text-xs font-bold text-gray-800">
                    Connect your slack account with <a href="https://www.labs.flow.zoimeet.com/myapps" target="_blank" className="text-blue-500" > flow app </a>
                </p>
            </div>}
        </div>
    )
}