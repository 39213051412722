import { connect } from "react-redux";

import Header from "../component/Header";
import { getMeetingDetails } from "../../../redux/Actions/meetingAction";

const mapStateToProps = state => {
    return {
        meetingData: state.meetingState.meetingData,
        loading: state.meetingState.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getMeetingDetails: (payload) => dispatch(getMeetingDetails(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)