import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import LayoutProvider from "./layout";
import Header from './header';
import CardTiles from './cardTiles';
import SmartNotes from './smartNotes';
import MyNotes from './MyNotes';
import Transcripts from './Transcripts';

import { getMeetingDetails } from '../redux/Actions/meetingAction';

import "../utils/CustomStyles.css";


function Zapp(props) {

    const [activeTile, setActiveTile] = useState(1);
    
    return (
        <LayoutProvider {...props} >
            <Header />
            <CardTiles activeTile={activeTile} setActiveTile={setActiveTile}>
               { activeTile == 1 && <SmartNotes /> }
               {/* { activeTile == 2 && <MyNotes /> }
               { activeTile == 3 && <Transcripts /> } */}
            </CardTiles>
        </LayoutProvider>
    )
}


const mapStateToProps = state => {
    return {
        meetingData: state.meetingState.meetingData,
        userData: state.userState.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getMeetingDetails: () => dispatch(getMeetingDetails())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Zapp)