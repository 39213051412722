
import {
    GET_USER,
    GET_USER_LOADER
} from "../types";


const initialState = {
    user: localStorage.getItem("_user") ? JSON.parse(localStorage.getItem("_user")): null,
    loggedIn: localStorage.getItem("token") ? true : false,
    token: localStorage.getItem("token"),
    loading: false
}


export default (state = initialState, { type, data }) => {
    switch (type) {
        case GET_USER:
            return { ...state, user: data.info, loggedIn: true, token: data.token }
        case GET_USER_LOADER:
            return { ...state, loading: data }
        default:
            return state
    }
}