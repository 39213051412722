import React from 'react';

import logo from "../../../utils/images/flowlogo.gif"

export default function Header(props) {
  return (
    <div className="flex flex-row justify-around pt-3 p-2">
      {props.loading ? <Skeleton /> : props.meetingData && <div>
        <p className="font-sans text-base font-bold p-1">{props.meetingData.meeting_name}</p>
        <p className="font-semibold text-sm text-opacity-25 opacity-25 pl-1">
          {new Date(props.meetingData.meeting_time).toDateString()}, {new Date(props.meetingData.meeting_time).toLocaleTimeString()}</p>
      </div>}
      <div>
        <img className="w-20 h-8 mt-2 ml-5" src={logo} alt="Flow by ZoiMeet" />
      </div>
    </div>
  );
}


const Skeleton = () => {
  return (
    <div class="p-1 max-w-sm w-full mx-auto">
      <div class="animate-pulse flex space-x-4">
        <div class="flex-1 space-y-4 py-1">
          <div class="space-y-2">
            <div class="h-4 bg-gray-300 rounded"></div>
            <div class="h-4 bg-gray-300 rounded w-5/6"></div>
          </div>
        </div>
      </div>
    </div>
  )
}