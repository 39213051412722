import * as actionTypes from "../types";
import axios from 'axios';

import meetingDetails from "../../mockData/meetingDetails.json"

import { urls } from "../../utils/constants";


export const getMeetingDetails = (data) => async dispatch => {
    dispatch(loaderAction(true));
    try {
        // dispatch(meetingDetailsSuccess(meetingDetails));
        let res = await axios.get(urls.liveMeeting, {
            headers: {
                token: localStorage.getItem("token")
            }
        });
        if (res && res.data && res.data.meetings && res.data.meetings.length > 0) {
            let slackToken = localStorage.getItem("slackToken");
            if(res.data.meetings[0].slack_token && !slackToken) {
                localStorage.setItem("slackToken", res.data.meetings[0].slack_token);
            }
            if(res.data.meetings[0].zoom_auth_token) {
                let user = localStorage.getItem("_user") ? JSON.parse(localStorage.getItem("_user")): {};
                user.zoom = true;
                localStorage.setItem("_user", JSON.stringify(user));
            }
            dispatch(meetingDetailsSuccess(res.data.meetings[0]));
            dispatch(loaderAction(false));
        } else {
            dispatch(meetingDetailsSuccess(null));
            dispatch(loaderAction(false));
        }
    } catch (e) {
        dispatch(meetingDetailsSuccess(null));
        dispatch(loaderAction(false));
    }
}


const meetingDetailsSuccess = (meetingData) => {
    return {
        type: actionTypes.GET_MEETING_DETAILS,
        data: meetingData
    }
}


const loaderAction = (isLoading) => {
    return {
        type: actionTypes.GET_MEETING_DETAILS_LOADER,
        data: isLoading
    }
}