import React, { useState, useEffect } from 'react';
import GoogleLogin from 'react-google-login';
import GoogleLogout from 'react-google-login';
import QueryString from 'query-string';

import flowmz from "../../../utils/images/flowmz.png";
import loading from "../../../utils/icons/loading.png";
import googleicon from "../../../utils/icons/googleicon.svg"


const ACCESS_SCOPE = "https://www.googleapis.com/auth/calendar";

export default function LoginComponent(props) {
    // console.log(props)


    const [hasCode, setCode] = useState(false);

    useEffect(() => {
        let url = window.location;
        const refCode = QueryString.parse(url.hash);
        if (refCode && refCode.code) {
            setCode(true);
            responseGoogle({ code: refCode.code });
        }
    }, []);


    const addIncrementalScopes = () => {
        props.loginLoaderAction(true);
        if (window.gapi) {
            console.log("addIncrementalScopes inside");
            var options = new window.gapi.auth2.SigninOptionsBuilder(
                {
                    // 'scope': 'https://www.googleapis.com/auth/calendar.readonly',
                    'scope': 'https://www.googleapis.com/auth/drive'
                }
            );
            let authInstance = window.gapi.auth2.getAuthInstance();
            let googleUser = authInstance.currentUser.get();

            authInstance.grantOfflineAccess({
                response_type: 'code',
                scope: ACCESS_SCOPE,
                prompt: 'consent',
                redirect_uri: process.env.REACT_APP_G_REDIRECT_URL
            }).then(response => {
                responseGoogle(response);
                console.log(JSON.stringify({ message: "SUCCEES", value: response }));
            }).catch(error => {
                console.log(JSON.stringify({ message: "fail", value: error }));
                responseError(error);
            })

        }
    }

    const responseError = (error) => {
        // console.log("error", error);
        props.loginLoaderAction(false);
        if (error.error === "access_denied") {
            alert("Access denied");
        }

    }


    const responseGoogle = (data) => {

        if (data) {
            let loginParams = {
                ...data,
                loginType: "Google",
                redirect_uri: process.env.REACT_APP_G_REDIRECT_URL
            }
            props.loginUserAction(loginParams, () => {
                setCode(false);
                window.location.href = process.env.REACT_APP_G_REDIRECT_URL;
            });
        }
    }


    return (
        <div className="w-full h-full flex flex-row-reverse" >
            <div className="backgroud sm: w-screen h-screen md:w-screen lg:w-screen border-4 border-light-gary-300 
            border-opacity-25 rounded-md shadow-sm p-1 relative bg-gray-50" >
                <div className="md:absolute top-1/4 right-1/3 mr-20 flex flex-col w-80 h-auto object-center rounded-lg bg-white p-10 shadow-lg" >
                    <div className="flex flex-col" >
                        <img src={flowmz} className="w-60 h-16 ml-3 p-1" />
                        <p className="opacity-75 font-semibold text-gray-500 ml-14 p-4" >Welcome</p>
                        {/* <p className="opacity-85 font-sm text-gray-600 ml-1 p-1" >Get started with your work email</p> */}
                        {/* <button onClick={login} type="button" className="bg-blue-600 rounded-md w-auto h-10 p-1 text-gray-100 hover:text-gray-200 hover:bg-blue-700 m-3" >Continue with Google</button> */}
                        {!hasCode ? <div onClick={addIncrementalScopes} >
                            <GoogleLogin
                                render={renderProps => (
                                    <button className="flex flex-row bg-blue-600 rounded-md w-auto h-10 text-gray-100 hover:text-gray-200 hover:bg-blue-700 m-3" type="button">
                                        <img alt='Google' className="w-10 h-10 p-1" src={googleicon} />
                                        <strong className="p-2 text-sm" >Continue with Google</strong>
                                    </button>
                                )}
                                clientId={process.env.REACT_APP_G_CLIENT_ID}
                                responseType='code'
                                // prompt='consent'
                                accessType='offline'
                                scope={ACCESS_SCOPE}
                                onSuccess={responseGoogle}
                                onFailure={responseError}
                                uxMode="redirect"
                            >
                            </GoogleLogin>
                        </div> :
                        <img src={loading} alt="Loader" class="animate-spin h-20 w-20 p-3 ml-16 mt-5" viewBox="0 0 24 24" /> 
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}
