

const baseUrl = process.env.REACT_APP_BASE_URL;

export const urls = {
    login: baseUrl + "/login",
    liveMeeting: baseUrl + "/meeting/current_meeting",
    workflows: baseUrl + "/workflow",
    triggers: baseUrl + "/meeting/zapp_meeting_status",
    emailTrigger: "https://hubspotlabs.flow.zoimeet.com/mail/livenotes/push",
    scheduleZoomEvent: baseUrl + "/zoom_schedule_meeting",
    scheduleCalendarEvent: baseUrl + "/meeting/calendar_schedule_meeting"
}