
import {
    GET_TRIGGERS,
    UPDATE_TRIGGERS,
    GET_TRIGGERS_LOADER,
    SHOW_NEXTSTEPS,
    SHOW_SCHEDULER,
    UPDATE_USER_NOTES
} from "../types";

// import dynamicTriggers from "../../mockData/triggers.json";


const initialState = {
    triggers: [],
    loading: false,
    showNextSteps: false,
    showScheduler: false,
    userNotes: []
}


export default (state = initialState, { type, data }) => {
    switch (type) {
        case GET_TRIGGERS:
            return { ...state, triggers: [...state.triggers , ...data], loading: false } 
        case UPDATE_TRIGGERS:
            return { ...state, triggers: data, loading: false }
        case UPDATE_USER_NOTES:
            return { ...state, userNotes: data }
        case GET_TRIGGERS_LOADER:
            return { ...state, loading: data }
        case SHOW_NEXTSTEPS:
            return { ...state, showNextSteps: data }
        case SHOW_SCHEDULER:
            return { ...state, showScheduler: data }            
        default:
            return state
    }
}