import React, { useState } from 'react';

import smartnotes_logo from "../../../utils/icons/note.png";
import mynotes_logo from "../../../utils/icons/mynotes_logo.png";
import transcripts_logo from "../../../utils/icons/transcripts_logo.png";


export default function CardTiles(props) {

    const changeTiles = (tile) => {
        props.setActiveTile(tile);
    }

    return (
        <div>
            <div className="flex flex-row justify-around shadow-md p-3">
                <div onClick={() => changeTiles(1)}
                    className={`flex flex-row cursor-pointer pt-3 p-2 hover:text-blue-600 ${props.activeTile == 1 && "text-blue-600"}`} >
                    <img className="w-5 h-5" src={smartnotes_logo} alt="Smart Notes" />
                    <p className="font-bold text-xs ml-1" >Smart Notes</p>
                </div>
                {/* <div onClick={() => changeTiles(2)}
                    className={`flex flex-row cursor-pointer pt-3 p-2 hover:text-blue-600 ${props.activeTile == 2 && "text-blue-600"}`} >
                    <img className="w-5 h-5" src={mynotes_logo} alt="Smart Notes" />
                    <p className="font-bold text-xs ml-1" >My Notes</p>
                </div>
                <div onClick={() => changeTiles(3)}
                    className={`flex flex-row cursor-pointer pt-3 p-2 hover:text-blue-600 ${props.activeTile == 3 && "text-blue-600"}`} >
                    <img className="w-5 h-5" src={transcripts_logo} alt="Smart Notes" />
                    <p className="font-bold text-xs ml-1" >Transcripts</p>
                </div> */}
            </div>
            <div>
                {props.children}
            </div>
        </div>
    );
}
