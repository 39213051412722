
import * as actionTypes from "../types";
import axios from "axios";

import { urls } from "../../utils/constants";


export const loginUserAction = (data, cb) => async dispatch => {
    // console.log("data", data);
    // dispatch(loginLoader(true));
    try {
        // debugger;
        let response = await axios.post(urls.login, data);
        if (response && response.data) {
            console.log("response", response)
            dispatch(loginSuccess(response.data));
            localStorage.setItem("_user", JSON.stringify(response.data.info));
            localStorage.setItem("token", response.data.token);
            if (response.data.info.slack_token) {
                localStorage.setItem("slackToken", response.data.info.slack_token);
            }
        }
        cb();
        // dispatch(loginLoader(false));
    } catch (e) {
        // dispatch(loginLoader(false));
        cb()
    }
}

export const loginLoaderAction = (isLoading) => async dispatch => {
    dispatch(loginLoader(isLoading));
}


const loginSuccess = (userDetails) => {
    return {
        type: actionTypes.GET_USER,
        data: userDetails
    }
}


const loginLoader = (isLoading) => {
    return {
        type: actionTypes.GET_USER_LOADER,
        data: isLoading
    }
}