
import React, { useEffect } from 'react';

import Zapp from "./components";


const App = () => {

  return (
    <Zapp />
  )
}

export default App;