
import {
    GET_WORKFLOW_DETAILS,
    GET_WORKFLOW_DETAILS_LOADER,
    UPDATE_SLACK_CHANNELS,
    GET_SLACK_CHANNELS_LOADING,
    SEND_SLACK_MESSAGE
} from "../types";


const workflowState = {
    workflows: null,
    loading: false,
    slackChannels: null,
    slackDirects: null,
    slackChannelsLoading: false,
    slackMessageSending: []
}


export default (state = workflowState, { type, data }) => {
    switch (type) {
        case GET_WORKFLOW_DETAILS:
            return { ...state, workflows: data }
        case GET_WORKFLOW_DETAILS_LOADER:
            return { ...state, loading: data }
        case UPDATE_SLACK_CHANNELS:
            return { ...state, slackChannels: data.channels, slackDirects: data.users, slackChannelsLoading: false }
        case GET_SLACK_CHANNELS_LOADING:
            return { ...state, slackChannelsLoading: data }
        case SEND_SLACK_MESSAGE:
            return { ...state, slackMessageSending: data }
        default:
            return state
    }
}