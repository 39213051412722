import React, { useState } from 'react';


export default function MyNotes(props) {

    return (
        <div className="h-screen w-full border-2 shadow-sm rounded-lg mt-5" >
            <div className="flex flex-row bg-blue-200 rounded-t-lg p-2" >
                <p className="ml-2 font-bold text-xs" >My Notes</p>
            </div>
        </div>
    );
}
