import { connect } from "react-redux";

import LoginComponent from "../component/LoginComponent";
import { loginUserAction, loginLoaderAction } from "../../../redux/Actions/userAction";

const mapStateToProps = state => {
    return {
        userData: state.userState.user,
        loading: state.userState.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loginUserAction: (payload, cb) => dispatch(loginUserAction(payload, cb)),
        loginLoaderAction: (payload) => dispatch(loginLoaderAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent)