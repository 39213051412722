
import {
    GET_MEETING_DETAILS,
    GET_MEETING_DETAILS_LOADER
} from "../types";


const meetingState = {
    meetingData: null,
    loading: false
}


export default (state = meetingState, { type, data }) => {
    switch (type) {
        case GET_MEETING_DETAILS:
            return { ...state, meetingData: data }
        case GET_MEETING_DETAILS_LOADER:
            return { ...state, loading: data }
        default:
            return state
    }
}