import { connect } from "react-redux";

import SmartNotes from "../component";

import { getMeetingDetails } from "../../../redux/Actions/meetingAction";
import { 
    getUserSlackChannels, 
    sendSlackMessage, 
    sendMailAction, 
    scheduleCalendarEvents 
} from "../../../redux/Actions/workflowAction";
import { getUserTriggers, updateTriggersAction, updateUserNotes, showNextStepsAction, showSchedulerAction } from "../../../redux/Actions/triggersAction";


const mapStateToProps = state => {
    return {
        meetingData: state.meetingState.meetingData,
        loading: state.meetingState.loading,
        availableUserWorkflows: state.workflowState.workflows,
        userWorkflowsLoading: state.workflowState.loading,
        userData: state.userState.user,
        triggers: state.triggerState.triggers,
        showNextSteps: state.triggerState.showNextSteps,
        showScheduler: state.triggerState.showScheduler,
        userNotes: state.triggerState.userNotes,
        triggerLoading: state.triggerState.loading,
        slackChannels: state.workflowState.slackChannels,
        slackDirects: state.workflowState.slackDirects,
        slackChannelsLoading: state.workflowState.slackChannelsLoading,
        sendingSlackMessage: state.workflowState.slackMessageSending
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getMeetingDetails: () => dispatch(getMeetingDetails()),
        updateTriggersAction: (payload) => dispatch(updateTriggersAction(payload)),
        getUserSlackChannels: (token) => dispatch(getUserSlackChannels(token)),
        getUserTriggers: (meetingData, cb) => dispatch(getUserTriggers(meetingData, cb)),
        sendSlackMessage: (token, data, cb) => dispatch(sendSlackMessage(token, data, cb)),
        sendMailAction: (token, data, cb) => dispatch(sendMailAction(token, data, cb)),
        scheduleCalendarEvents: (token, data, cb) => dispatch(scheduleCalendarEvents(token, data, cb)),
        updateUserNotes: (notes) => dispatch(updateUserNotes(notes)),
        showNextStepsAction: (show) => dispatch(showNextStepsAction(show)),
        showSchedulerAction: (show) => dispatch(showSchedulerAction(show))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SmartNotes)