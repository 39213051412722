
import React, { useEffect } from 'react';

import LoginComponent from '../../login';

import flowmz from "../../../utils/images/flowmz.png";
import loading from "../../../utils/icons/loading.png";


const withAuthentication = (Layout) => ({ ...props }) => {
    if (props.loading) {
        return <Loader />
    } else if (props.user && props.token && props.loggedIn) {
        return <Layout {...props} />
    } else {
        return <LoginComponent />
    }
}


const Loader = () => {
    return (
        <div className="w-full h-screen flex flex-row-reverse" >
            <div className="backgroud sm: w-screen h-screen md:w-screen lg:w-screen border-4 border-light-gary-300 
            border-opacity-25 rounded-md shadow-sm p-1 relative bg-gray-50" >
                <div className="md:absolute top-1/4 right-1/3 mr-20 flex flex-col w-80 h-auto object-center rounded-lg bg-white p-10 shadow-lg" >
                    <div className="flex flex-col" >
                        <img src={flowmz} className="w-60 h-16 ml-3 p-1" />
                        <img src={loading} alt="Loader" class="animate-spin h-20 w-20 p-3 ml-16 mt-5" viewBox="0 0 24 24" />
                    </div>
                </div>
            </div>
        </div>
    )
}


// const NoMeeting = () => {
//     return (
//         <div className="w-full h-screen flex flex-row-reverse" >
//             <div className="sm: w-full md:w-5/12 lg:w-1/3 border-4 border-light-gary-300 
//                     border-opacity-25 rounded-md divide-y divide-black divide-opacity-25 shadow-sm p-1" >
//                 <div className="w-full h-full flex flex-row-reverse" >
//                     <div className="sm: w-screen h-screen md:w-screen lg:w-screen border-4 border-light-gary-300 
//             border-opacity-25 rounded-md shadow-sm p-1 relative bg-gray-50" >
//                         <div className="flex flex-col" >
//                             <img src={flowmz} className="w-60 h-16 ml-3 p-1" />
//                             <h1 className="p-5 mt-14 ml-10 text-gray-600 animate-pulse font-semibold" > No live meetings found ...</h1>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }


const Layout = (props) => {
    useEffect(() => {
        props.getMeetingDetails();
        // window.addEventListener("storage", (e) => {
        //     if (e.key === "token" || e.key === "_user") {
        //         localStorage.removeItem("token");
        //         localStorage.removeItem("_user");
        //         localStorage.removeItem("slackToken");
        //         window.location.reload();
        //     }
        // })
    }, []);
    return (
        <div className="w-full h-auto flex flex-row-reverse" >
            <div className="sm: w-full md:w-5/12 lg:w-1/3 border-4 border-light-gary-300 
                    border-opacity-25 rounded-md divide-y divide-black divide-opacity-25 shadow-sm p-1" >
                {props.children}
            </div>
        </div>
    )
}


export default withAuthentication(Layout)