import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import scheduleicon from "../../../utils/icons/calendar.png";
import notesicon from "../../../utils/icons/notesicon.png";
import meetingData from "../../../mockData/meetingDetails.json";
import Snackbar from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';
import MuiAlert from '@mui/material/Alert';
import loading from "../../../utils/icons/loading.png";
import Tooltip from '@mui/material/Tooltip';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function ScheduleMeeting(props) {

    console.log("SCEDHULE MEET", props);
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");

    const [userDefinedTriggers, setUserDefinedTriggers] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());
    const [participantsList, setParticipantsList] = useState([]);
    const [participantsListString, setParticipantsListString] = useState("");
    const [nextMeetingUrl, setMeetingUrl] = useState("");
    const [loader, setLoading] = useState(false);
    const [notify, setNotify] = useState({
        show: false,
        type: "",
        msg: ""
    });

    const inviteAllParticipants = () => {
        /* if (participantsList.includes(props.meetingData.attendees[0])) {
            let newList = participantsList.filter(i => !props.meetingData.attendees.includes(i) ? i : null);
            setParticipantsList(newList);
            let str = "";
            newList.map(i => {
                str += i + ","
            });
            setParticipantsListString(str);
        } else {
            let newList = [...participantsList, ...props.meetingData.attendees];
            setParticipantsList(newList);
            let str = participantsListString;
            newList.map(i => {
                str += "," + i
            });
            setParticipantsListString(str);
        }
 */
        if (participantsListString.includes(props.meetingData.attendees)) {
            setParticipantsListString(participantsListString.replace(props.meetingData.attendees, ""))
        } else if (props.meetingData.attendees) {
            setParticipantsList(props.meetingData.attendees.split(","))
            setParticipantsListString(participantsListString + props.meetingData.attendees)
        }

    }

    const handleChange = (e) => {
        setParticipantsListString(e.target.value);
    }

    const handleMeetingUrl = (e) => {
        setMeetingUrl(e.target.value);
    }

    const schedule = () => {
        setLoading(true);
        var stime = moment(startTime).format("HH:mm");
        var sdate = moment(startDate).format("YYYY-MM-DD");
        var combined = sdate + " " + stime;
        const startdate = new Date(combined);
        var endDate = moment(startdate).add(1, 'hours');
        const formatedStartDate = startdate.toISOString();
        const formatedEndDate = endDate.toISOString();

        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        var emailList = []
        participantsList.forEach((element) => {
            var obj = {
                email: element.trim()
            }
            emailList.push(obj)
        })


        let data_sch = {
            title: `Follow up ${props.meetingData.meeting_name}`,
            start: formatedStartDate,
            end: formatedEndDate,
            timeZone: timeZone,
            attendees: emailList
        }

        console.log(JSON.stringify(data_sch));
        let token = localStorage.getItem("token");
        props.scheduleCalendarEvents(token, data_sch, (success) => {
            if (success) {
                setNotify({
                    show: true,
                    type: "success",
                    msg: "Event added to calendar"
                });
                setLoading(false);
                props.showSchedulerAction(false);
                setTimeout(() => {
                    setLoading(false)
                    setNotify({
                        show: false,
                        type: "",
                        msg: ""
                    });
                    // removeTrigger(props.triggerItem.id)
                }, 2000);
            } else {
                setLoading(false);
                setNotify({
                    show: true,
                    type: "error",
                    msg: "Failed to add event"
                });
                setTimeout(() => {
                    setNotify({
                        show: false,
                        type: "",
                        msg: ""
                    });
                }, 2000);
            }
        })

    }

    const removeTrigger = () => {
        // let newTriggers = props.triggers.filter(i => i.id != triggerId);
        // props.updateTriggersAction(newTriggers);
        props.showSchedulerAction(false);
    }

    return (
        <div className="h-full w-full border-2 shadow-sm rounded-lg mt-3" >

            <Snackbar
                open={notify.show}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={3000}
                TransitionComponent={Fade}
                key={Fade.name}>
                <Alert severity={notify.type} sx={{ width: '100%' }}>
                    {notify.msg}
                </Alert>
            </Snackbar>

            <div style={{ background: "rgb(249,232,213)" }} className="flex flex-row rounded-t-lg p-2" >
                <img src={scheduleicon} alt="icon" className="w-5 h-5 ml-2" />
                <p className="ml-2 font-bold text-xs" >Schedule next meeting</p>
            </div>
            <div className="flex flex-col pl-3 pt-1 pr-3" >


                <div className="flex flex-col p-3 ml-5" >
                    {props.userData && props.userData.zoom ? <><div className="flex flex-row p-1" >
                        <span className="text-xs p-2" >Date:</span>
                        <DatePicker todayButton="Today" className="ml-5 text-sm w-24 h-8 p-3 rounded-md bg-gray-100" selected={startDate} onChange={(date) => setStartDate(date)} />
                    </div>
                    <div className="flex flex-row p-1" >
                        <span className="text-xs p-2" >Time:</span>
                        <DatePicker className="ml-5 w-24 h-8 p-3 text-sm rounded-md bg-gray-100"
                            showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm aa"
                            selected={startTime} onChange={(time) => setStartTime(time)} />
                    </div>
                    <div className="flex flex-row p-2" >
                        <input onChange={inviteAllParticipants} type="checkbox" className="text-sm mt-1 p-1 h-4 w-6" />
                        <span className="text-sm ml-3 opacity-60 font-semibold" >Invite all participants of this meeting</span>
                    </div>
                    <div className="flex flex-row p-2" >
                        <span className="text-xs p-2" >Add:</span>
                        <input onBlur={(e) => setParticipantsList(participantsListString.split(","))} value={participantsListString} onChange={handleChange} type="text" placeholder="Add participants to invite" className="bg-gray-200 w-80 ml-5 rounded-sm p-2 border border-opacity-20 border-gray-400 text-xs" />
                    </div></> : <div className="col-span-6 sm:col-span-3 p-2">
                <p className="block p-3 text-xs font-bold text-gray-800">
                    Connect your zoom account with <a href="https://www.labs.flow.zoimeet.com/myapps" target="_blank" className="text-blue-500" > flow app </a>
                </p>
            </div>
                    }
                    {/* <div className="flex flex-row" >
                        <span className="text-sm ml-3 opacity-60 font-semibold" >Meeting Details</span>
                    </div>
                    <div className="flex flex-row p-2" >
                        <textarea onChange={handleMeetingUrl} type="textarea" placeholder="Add meeting url" className="bg-gray-200 w-80 ml-5 rounded-sm p-2 border border-opacity-20 border-gray-400 text-xs" rows="8" cols="100%">{nextMeetingUrl}</textarea>
                    </div> */}
                    {/*                     <div className="flex flex-row p-2" >
                        <span className="text-xs p-2" >Meeting Details:</span>
                        <input value={nextMeetingUrl} onChange={handleMeetingUrl} type="textarea" placeholder="Add meeting url" className="bg-gray-200 w-80 ml-5 rounded-sm p-2 border border-opacity-20 border-gray-400 text-xs" />
                    </div> */}
                    <div className="flex flex-row p-2" >
                    { loader ?
                            <img src={loading} className={`p-2 ml-3 mt-2 animate-spin hover:shadow-2xl hover:bg-gary-700 object-contain rounded-md bg-gary-800 object-center h-8 w-10`} />
                       : participantsList.length > 0 && participantsListString.length > 0 ? <button onClick={schedule} type="button" className="rounded-md hover:shadow-lg h-10 w-24 text-sm font-semibold text-white bg-blue-600 hover:bg-blue-700" disabled={loader} > Schedule </button>
                        : <Tooltip title="Add emails to schedule">
                        <button type="button" className="rounded-md hover:shadow-lg cursor-not-allowed h-10 w-24 text-sm font-semibold text-white bg-gray-400 hover:bg-gray-500" disabled > Schedule </button>
                        </Tooltip>}
                        <button onClick={() => removeTrigger()} type="button" className="rounded-md hover:shadow-lg h-10 w-24 border text-gray-600 hover:text-gray-800 border-black border-opacity-50 ml-5" > Dismiss </button>
                    </div>
                </div>

            </div>
        </div>
    );
}